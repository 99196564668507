// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.mjs";
import * as Core__Option from "@rescript/core/src/Core__Option.mjs";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.mjs";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.mjs";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.mjs";
import * as GlobalAppStateQuery_graphql from "../__generated__/GlobalAppStateQuery_graphql.mjs";
import * as GlobalAppState_appState_graphql from "../__generated__/GlobalAppState_appState_graphql.mjs";

var convertVariables = GlobalAppStateQuery_graphql.Internal.convertVariables;

var convertResponse = GlobalAppStateQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse = GlobalAppStateQuery_graphql.Internal.convertWrapRawResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, GlobalAppStateQuery_graphql.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, GlobalAppStateQuery_graphql.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(GlobalAppStateQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(GlobalAppStateQuery_graphql.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(GlobalAppStateQuery_graphql.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(GlobalAppStateQuery_graphql.node, convertVariables);

var commitLocalPayload = RescriptRelay_Query.commitLocalPayload(GlobalAppStateQuery_graphql.node, convertVariables, convertWrapRawResponse);

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain,
  commitLocalPayload: commitLocalPayload
};

var convertFragment = GlobalAppState_appState_graphql.Internal.convertFragment;

function waitForFragmentData(environment, fRef) {
  return RescriptRelay_Fragment.waitForFragmentData(environment, GlobalAppState_appState_graphql.node, convertFragment, fRef);
}

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(GlobalAppState_appState_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, GlobalAppState_appState_graphql.node, convertFragment);
}

function readResolverFragment(fRef) {
  return RescriptRelay_Fragment.read(GlobalAppState_appState_graphql.node, convertFragment, fRef);
}

var AppSateFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  waitForFragmentData: waitForFragmentData,
  use: use$1,
  useOpt: useOpt,
  readResolverFragment: readResolverFragment
};

function getEmptyAppState() {
  return {
          campaignIdsSelectedForComparison: []
        };
}

function loadPersistedAppState() {
  var dict;
  try {
    dict = JSON.parse(window.sessionStorage.getItem("informind/persisted-app-state/v0"));
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e.RE_EXN_ID === Js_exn.$$Error) {
      console.error(e._1);
      return {
              campaignIdsSelectedForComparison: []
            };
    }
    throw e;
  }
  if (!Array.isArray(dict) && (dict === null || typeof dict !== "object") && typeof dict !== "number" && typeof dict !== "string" && typeof dict !== "boolean") {
    return {
            campaignIdsSelectedForComparison: []
          };
  }
  if (!(typeof dict === "object" && !Array.isArray(dict))) {
    return {
            campaignIdsSelectedForComparison: []
          };
  }
  var match = dict["campaignIdsSelectedForComparison"];
  var campaignIdsSelectedForComparison = match !== undefined ? (
      !Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean" ? [] : (
          Array.isArray(match) ? Core__Array.filterMap(match, (function (item) {
                    if (!Array.isArray(item) && (item === null || typeof item !== "object") && typeof item !== "number" && typeof item !== "string" && typeof item !== "boolean") {
                      return ;
                    }
                    if (!Array.isArray(item)) {
                      return ;
                    }
                    if (item.length !== 2) {
                      return ;
                    }
                    var organizationSlug = item[0];
                    if (!Array.isArray(organizationSlug) && (organizationSlug === null || typeof organizationSlug !== "object") && typeof organizationSlug !== "number" && typeof organizationSlug !== "string" && typeof organizationSlug !== "boolean") {
                      return ;
                    }
                    if (typeof organizationSlug !== "string") {
                      return ;
                    }
                    var campaignId = item[1];
                    if (!Array.isArray(campaignId) && (campaignId === null || typeof campaignId !== "object") && typeof campaignId !== "number" && typeof campaignId !== "string" && typeof campaignId !== "boolean" || typeof campaignId !== "string") {
                      return ;
                    } else {
                      return {
                              campaignId: campaignId,
                              organizationSlug: organizationSlug
                            };
                    }
                  })) : []
        )
    ) : [];
  return {
          campaignIdsSelectedForComparison: campaignIdsSelectedForComparison
        };
}

function savePersistedAppState(appState) {
  var asJson = Object.fromEntries([[
          "campaignIdsSelectedForComparison",
          appState.campaignIdsSelectedForComparison.map(function (param) {
                return [
                        param.organizationSlug,
                        param.campaignId
                      ];
              })
        ]]);
  window.sessionStorage.setItem("informind/persisted-app-state/v0", JSON.stringify(asJson));
}

var PersistedAppState = {
  loadPersistedAppState: loadPersistedAppState,
  savePersistedAppState: savePersistedAppState
};

var Internal = {
  Query: Query,
  AppSateFragment: AppSateFragment,
  getEmptyAppState: getEmptyAppState,
  PersistedAppState: PersistedAppState
};

function clearPersistedAppState() {
  window.sessionStorage.removeItem("informind/persisted-app-state/v0");
}

function useGlobalAppStateOpt() {
  var match = use(undefined, "store-only", undefined, undefined);
  return useOpt(Core__Option.map(match.appState, (function (appState) {
                    return appState.fragmentRefs;
                  })));
}

function useSetGlobalAppState() {
  var appState = useGlobalAppStateOpt();
  var environment = RescriptRelay.useEnvironmentFromContext();
  var appStateRef = React.useRef(appState);
  appStateRef.current = appState;
  return React.useMemo((function () {
                return function (setter) {
                  var appState = Core__Option.map(appStateRef.current, (function (appState) {
                          return {
                                  campaignIdsSelectedForComparison: appState.campaignIdsSelectedForComparison.map(function (item) {
                                        return item;
                                      })
                                };
                        }));
                  var currentAppState = appState !== undefined ? appState : ({
                        campaignIdsSelectedForComparison: []
                      });
                  var newAppState = setter(currentAppState);
                  savePersistedAppState(newAppState);
                  commitLocalPayload(environment, undefined, {
                        appState: newAppState
                      });
                };
              }), [environment]);
}

function useInitGlobalAppState() {
  var appState = useGlobalAppStateOpt();
  var setGlobalAppState = useSetGlobalAppState();
  if (appState !== undefined) {
    return ;
  } else {
    return setGlobalAppState(function (param) {
                return loadPersistedAppState();
              });
  }
}

export {
  Internal ,
  clearPersistedAppState ,
  useGlobalAppStateOpt ,
  useSetGlobalAppState ,
  useInitGlobalAppState ,
}
/* use Not a pure module */
