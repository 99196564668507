// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as RescriptRelay from "rescript-relay/src/RescriptRelay.mjs";

var Types = {};

var fragmentConverter = {"__root":{"":{"f":""}}};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

function metricalPartsTypeEnum_decode($$enum) {
  if ($$enum === "spent" || $$enum === "sessions" || $$enum === "recurring_costs" || $$enum === "leads" || $$enum === "impressions" || $$enum === "conversions" || $$enum === "clicks" || $$enum === "total_cost") {
    return $$enum;
  }
  
}

function metricalPartsTypeEnum_fromString(str) {
  return metricalPartsTypeEnum_decode(str);
}

var Utils = {
  metricalPartsTypeEnum_decode: metricalPartsTypeEnum_decode,
  metricalPartsTypeEnum_fromString: metricalPartsTypeEnum_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SingleMetricDrilldown_metrics",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "includeTrendGraph",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "SingleMetricSummaryBoxMetricDisplay_metric"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SingleMetricDrilldownPartsView_metric"
    },
    {
      "kind": "RequiredField",
      "field": {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 200
          }
        ],
        "concreteType": "MetricalPartsConnection",
        "kind": "LinkedField",
        "name": "parts",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "includedPartsSectionTypes",
              "storageKey": null
            },
            "action": "NONE"
          }
        ],
        "storageKey": "parts(first:200)"
      },
      "action": "NONE"
    }
  ],
  "type": "CombinedComparableMetricType",
  "abstractKey": null
};

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* RescriptRelay Not a pure module */
